<!--
用途：市、区、街道、社区的级联选择
传参： orgCode:'44', //默认为44,即广东省开始查起
       resetNum:0 , //默认为0，改变了就会触发清空的方法，一般用于重置使用，让resetNum自增1即可
暴露出去的方法： changeCasader ，选择了每一项之后的数据

-->

<template>
  <div>
       <Cascader @on-change="changeCasder" transfer :load-data="loadData" change-on-select style="margin-right: 10px; width: 350px" :data="CascaderList" :clearable="false" v-model="CascaderId" :placeholder="orgCode == '44' ?'请选择市/区/街道/社区':'请选择'"></Cascader>
  </div>
</template>

<script>
export default {
    props:{
        orgCode:{
            type:String,
            default:()=>{
                return '44'
            }
        },
        resetNum:0,

        list:{}
    },
    data(){
        return{
            // 存放选择的社区
            CascaderList:[],
            CascaderId:[],
            isOnce:false,


            // 存放社区信息数组
            orgLevelList:[]
        }
    },
    created(){
        console.log('list',this.list);
        this.getCascader()
    },
    methods:{
         // 获取级联
        getCascader(){
            this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:this.orgCode,
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
            }).then(res=>{
                if(res.code == 200 && res.dataList.length != 0){
                    let list = []
                    this.orgLevelList = []
                    res.dataList.map(item =>{
                       if(item.orgLevel == '2' && this.list.cityList && this.list.cityList.length){
                           this.list.cityList.map(items =>{
                               if(item.orgCode == items){
                                   list.push(item)
                               }
                           })
                       }else if(item.orgLevel == '3' && this.list.regionList && this.list.regionList.length){
                           this.list.regionList.map(items =>{
                               if(item.orgCode == items){
                                   list.push(item)
                               }
                           })
                       }else if(item.orgLevel == '4' && this.list.streeList && this.list.streeList.length){
                           this.list.streeList.map(items =>{
                               if(item.orgCode == items){
                                   list.push(item)
                               }
                           })
                       }else if(item.orgLevel == '5' && this.list.orgList && this.list.orgList.length){
                           this.list.orgList.map(items =>{
                               if(item.orgCode == items){
                                   list.push(item)
                                   this.orgLevelList.push(item)
                               }
                           })
                       }
                    })
                    if(list && list.length){
                        res.dataList = list
                    }
                    this.CascaderList = this.forMatterTree(res.dataList)
                    if(this.CascaderList.length != 0){
                        if(this.resetNum == 0){
                            this.CascaderId.push(this.CascaderList[0].value)
                            this.isOnce = true
                            this.$emit('changeCasader',this.CascaderId)
                        }         
                    }
                    this.CascaderList.map(item =>{
                        item.loading = false
                    })
                }
            })
        },
        changeCasder(val,item){
                 if(val.length == 3){
                    this.$emit('changeCasader',val)
                    return
                }
                if(this.resetNum != 0 || !this.isOnce){
                    this.$emit('changeCasader',val)
                }
        },
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName;
                data[i].value = data[i].orgCode;
                if (data[i].children && data[i].children != null && data[i].children.length > 0 ) {
                    this.forMatterTree(data[i].children);
                }
            }
            return data;
        },
       async loadData (item, callback) {
            item.loading = true
            let params = {
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCode:item.orgCode,
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
            };
           await this.$get('/orgzz/pc/organization/selectCascadeDataScope',{
                ...params
            }).then(res=>{
                if(res.code == 200 && res.dataList.length != 0){
                    let list = []
                    res.dataList.map(item =>{
                       if(item.orgLevel == '2' && this.list.cityList && this.list.cityList.length){
                           this.list.cityList.map(items =>{
                               if(item.orgCode == items){
                                   list.push(item)
                               }
                           })
                       }else if(item.orgLevel == '3' && this.list.regionList && this.list.regionList.length){
                           this.list.regionList.map(items =>{
                               if(item.orgCode == items){
                                   list.push(item)
                               }
                           })
                       }else if(item.orgLevel == '4' && this.list.streeList && this.list.streeList.length){
                           this.list.streeList.map(items =>{
                               if(item.orgCode == items){
                                   list.push(item)
                               }
                           })
                       }else if(item.orgLevel == '5' && this.list.orgList && this.list.orgList.length){
                           this.list.orgList.map(items =>{
                               if(item.orgCode == items){
                                   list.push(item)
                               }
                               this.orgLevelList.push(item)
                           })
                       }
                    }) 
                    if(list && list.length){
                        res.dataList = list
                    }
                    let arr = this.forMatterTree(res.dataList)
                    arr.map(items =>{
                        if(items.orgLevel == 4){
                            items.loading = false
                        }else if(items.orgLevel == 3){
                            items.loading = false
                        }
                    })
                    item.loading = false
                    item.children = arr
                    if(this.resetNum == 0){
                         this.CascaderId.push(arr[0].value)
                         this.$emit('changeCasader',this.CascaderId)
                    }
                    callback(item.children);
                }else{
                     callback([]);
                }

            })
        },
    },
    watch:{
        resetNum(val){
                this.CascaderId = []
        }
    }
}
</script>

<style>

</style>