<template>
  <LiefengContent isBack="true" @backClick="changeBack">
    <template v-slot:title>
        {{isOrg ? $route.query.title || '核酸检测情况总览' : `社区动员体系 ${titleName}` }}
    </template>
    <template v-slot:toolsbarRight>

    </template>
    <template v-slot:contentArea>
        <StreeContainer v-show="isOrg" :tableData="tableData" ref="StreeContainer" @changeCasader="changeCasader" :list="list" @changeGridItem="changeGridItem"></StreeContainer>
        <OrgContainer v-if="!isOrg" :tableList="tableList" :tableData="orgTableData">

        </OrgContainer>
    </template>
  </LiefengContent>
</template>

<script>

import LiefengModal from "@/components/LiefengModal";
import LiefengContent from "@/components/LiefengContent3";
import BuildSvg from './components/buildsvg'
import RegionSvg from './components/regionsvg'
import StreeContainer from './components/streeContainer'
import OrgContainer from './components/orgContainer'
export default {
  components: {
    LiefengContent,
    LiefengModal,
    BuildSvg,
    RegionSvg,
    StreeContainer,
    OrgContainer,

  },
  data() {
    return {
        list:{
            // 存放市的数组
            cityList:[],
            // 存放区的数组
            regionList:[],
            // 存放街道的数组
            streeList:[],
            // 存放社区的数组
            orgList:[]
        },

        orgName:'',

        tableData:[],

        //当前是是否是社区层级的
        isOrg:true,
        titleName:'',

        // 网格点进去的数据
        orgTableData:{},

        tableList:{}


        
    }
  },
  created() {
    this.getDataScopeList()
  },
  mounted() {

  },
  methods: {
      // 点击了单个网格
      changeGridItem(item,items){
          this.orgTableData = {}
          this.tableList = {}
          if(item && item.childs && item.childs.length){
              this.isOrg = false
              this.$nextTick(()=>{
                  this.orgTableData = items
                  this.tableList = items
                  console.log('this.tableList',this.tableList);
              })
              this.titleName = `${this.orgName} > ${items.name}` 
          }
          
      },   
      // 返回按钮控制
      changeBack(){
          if(this.isOrg){
              this.$router.push(`/activityrecord?dataScopeList=${this.$route.query.dataScopeList}&title=${this.$route.query.title}&formId=${this.$route.query.formId}&id=${this.$route.query.formId}&functionType=${this.$route.query.functionType}&menuCode=${this.$route.query.menuCode}&period=${this.$route.query.period}&whetherSign=${this.$route.query.whetherSign}&menuId=${this.$route.query.menuId}`)
          }else{
              this.isOrg = true
          }
      },   
      // 获取当前信息的社区权限
      getDataScopeList(){
          let data = JSON.parse(this.$route.query.dataScopeList)
          if(data && data.length){
              data.map(item =>{
                  if(item && item.length){
                      item.map((items,index) =>{
                          switch(index){
                              case 1 :
                                  this.list.cityList.push(items)
                              break;
                              case 2 :
                                  this.list.regionList.push(items)
                              break;
                              case 3 :
                                  this.list.streeList.push(items)
                              break;
                              case 4:
                                  this.list.orgList.push(items)
                              break
                          }
                      })
                  }
              })
          }
          this.list.cityList = [...new Set(this.list.cityList)]
          this.list.regionList = [...new Set(this.list.regionList)]
          this.list.streeList = [...new Set(this.list.streeList)]
          this.list.orgList = [...new Set(this.list.orgList)]
      },
     // 获取到了社区之后
     changeCasader(val){
         let data = this.$refs.StreeContainer.$refs.LiefengCascaderCity.orgLevelList
         if(val && val.length && data && data.length){
             data.map(item =>{
                 val.map(items =>{
                     if(item.orgCode == items){
                         this.orgName = item.orgName
                     }
                 })
             })
         }
        
         // 获取接口数据
         this.getList(val[val.length - 1])
     },
     // 接口部分
     getList(orgCode){
         this.$Message.loading({content: '正在加载数据',duration:0});
         try{
             this.$get('/syshequ/api/sytop/pc/grid/buildGridArchitectureTree',{
                orgCode:orgCode,
                informationId: this.$route.query.formId
            }).then(res=>{
               this.$Message.destroy();
                if(res && res.code == 200){
                    this.tableData = res.data
                }else{
                    this.$Message.error({
                        content:'获取信息失败',
                        background:true
                    });
                    return
                }
            })
         }catch(e){
             this.$Message.destroy();
             this.$Message.error({
                 content:'获取信息失败',
                 background:true
             });
         }
         
     }   
  }
}
</script>

<style scoped lang="less">
/deep/ #contentarea{
    background: #2392ae;
}
</style>
