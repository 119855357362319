<template>
  <svg t="1621301538576" class="icon iconfont" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg"
                 p-id="29231" width="32" height="32"><path
                d="M653.62 920.47H338.69m121.33-539.73h-41.37v81.37h41.37v-81.37z m135.44 0h-41.37v81.37h41.37v-81.37zM460.02 525.83h-41.37v71.37h41.37v-71.37z m135.44 0h-41.37v71.37h41.37v-71.37z m-135.44 131h-41.37v81.37h41.37v-81.37z m135.44 0h-41.37v81.37h41.37v-81.37zM460.02 788.32h-41.37v81.37h41.37v-81.37z m135.44 0h-41.37v81.37h41.37v-81.37z m0 0"
                p-id="29232" fill="#B0B0B0"></path><path
                d="M722.02 961.85H277.66V125.54s-0.66-14.43 5.69-21.35c6.34-6.91 26.26-6.82 26.26-6.82l386.2-0.09s15.66-0.46 20.94 6.91c5.28 7.38 4.87 21.76 4.87 21.76v835.9h0.4z m-401.35-41.38h358.34V140.28H320.67v780.19z m0 0"
                p-id="29233" fill="#B0B0B0"></path><path
                d="M416.65 224.74h41.37v81.37h-41.37zM552.09 224.74h41.37v81.37h-41.37z" p-id="29234"
                fill="#B0B0B0"></path><path
                d="M249.3 942.85h507.92M757.22 962.85H249.3c-11.05 0-20-8.95-20-20s8.95-20 20-20h507.91c11.05 0 20 8.95 20 20s-8.95 20-19.99 20z"
                p-id="29235" fill="#B0B0B0">
    </path>
  </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>