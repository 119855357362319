<template>
  <div class="stree-div">
    <div class="select-div">
        <span style="margin-right:10px;padding-top:5px;color:#fff">社区动员组织体系</span> 
        <LiefengCascaderCity ref="LiefengCascaderCity" :resetNum="0" :orgCode="4401" :list="list" @changeCasader="changeCasader"></LiefengCascaderCity>
    </div>
    <div class="top-title">
        <div class="left">
          <div class="title-container">
              <img src="https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20220325/503513808.png" alt="">
          </div>
          <div class="left-box-shadow">
              <div class="item-div" v-if="boxList && boxList.length">
              <div :class="item.class" v-for="(item,index) in boxList" :key="index">
                <div class="top-div">
                    <div class="div-left">
                        <div class="title">{{item.title}}</div>
                        <div class="num">{{item.num}} <span class="span">{{item.pre}}</span></div>
                    </div>
                </div>
            </div> 
            </div>
            <div class="item-div" v-else>
                    <div class="item-none" style="line-height:170px;color:#ccc">
                        暂无数据
                    </div>
                </div> 
            </div>
          
        </div>
        <div class="right">
            <div class="title-container">
                <img src="https://liefeng.oss-cn-shenzhen.aliyuncs.com/PROD/zjsm/20220325/881308791.png" alt="">
            </div>
            <div class="right-box-shadow">
                <div class="item-div">
                <div class="item margin-item right-item background1">
                    <div class="top-div">
                        <div class="div-left div-left-other">
                            <div class="title">已检:</div>
                            <div class="num-div">
                                <div class="num">{{tableData.appointmentHouseNum || 0}} <span class="span">户</span></div>
                                <div class="num">{{tableData.appointmentUser || 0}} <span class="span">人</span></div>
                            </div>
                            
                        </div>
                    </div>
                 </div>  
                <div class="item right-item background2">
                    <div class="top-div">
                        <div class="div-left div-left-other">
                            <div class="title">未检:</div>
                            <div class="num-div">
                                <div class="num red">{{(tableData.houseNum - tableData.appointmentHouseNum) > 0 ? (tableData.houseNum - tableData.appointmentHouseNum) : 0}} <span class="span">户</span></div>
                                <div class="num red">{{(tableData.onlineUser - tableData.appointmentUser) > 0 ? (tableData.onlineUser - tableData.appointmentUser) : 0}} <span class="span">人</span></div>
                            </div>
                        </div>
                    </div>
                 </div>  
          </div> 
            </div>
            
        </div>
    </div>

    <div class="bottom-div" v-for="(item,index) in gridList" :key="index">
        <div class="bottom-item" v-for="(items,indexs) in item.childs" :key="indexs" @click="changeGridItem(item,items,index)">
            <div class="padding-item-div">
                <div class="bottom-item-title">
                {{items.name}}
                </div>
                <div style="margin:20px 0;max-height: 60px;text-overflow: -o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;line-clamp: 2;-webkit-box-orient: vertical;" v-if="items.manages && items.manages.length">
                    <div class="bottom-item-person" v-for="(itemss,indexss) in items.manages" :key="indexss">
                        {{itemss}}
                    </div>
                </div>
                <div style="margin:20px 0" class="bottom-item-person" v-else>
                    <div>网格党支部: *</div>
                    <div>网格党支部书记: *</div>
                    <div>网格长: *</div>
                </div>
                <div class="bottom-item-last">
                    <div>
                        已检: <span style="color:67a80f">{{items.appointmentUser || 0}}</span>  人
                    </div>
                    <div>
                        未检: <span style="color:red">{{(items.onlineUser - items.appointmentUser >= 0 ) ? items.onlineUser - items.appointmentUser : 0}}</span> 人
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  </div>
</template>

<script>
import LiefengCascaderCity from './LiefengCascaderCity.vue'
export default {
    props:{
        list:{},
        tableData:[]
    },
    components:{LiefengCascaderCity},
    data(){
        return{
            boxList:[],
            gridList:[]
        }
    },
    methods:{
        changeCasader(val){
            if(val && val.length == 3){
                this.$emit('changeCasader',val)
            }   
        },
        changeGridItem(item,items){
            this.$emit('changeGridItem',item,items)
        }
    },
    watch:{
        tableData(val){
            if(val){
                this.boxList = [
                    {title:'网格数',num:val.gridTotal || 0,class:'item left-item background1',pre:'个'},
                    {title:'楼组/楼栋数',num:val.buildingGroupTotal || 0,class:'item left-item background2',pre:'个'},
                    {title:'总户数',num:val.houseTotal || 0,class:'item left-item background3',pre:'户'},
                    {title:'总人数',num:val.onlineUser,class:'item left-item background4',pre:'人'},
                ]
                if(val.tree){
                    this.gridList = val.tree
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .stree-div{
        width: 100%;
        user-select: none;
        .select-div{
            width: 100%;
            padding: 20px;
            font-size: 24px;
            font-weight: bold;
            display: flex;
        }
        .top-title{
            display: flex;
             .left{
                width: 60%;
                // border: 1px solid #ccc;
                height: 170px;
                border-radius: 10px;
                margin-right: 20px;
                background: #ffffff;
                box-shadow: 0 0 8px rgba(0,0,0,0.25);
                position: relative;
                cursor: pointer;
                 &:hover{
                     box-shadow: 0px 0px 13px rgba(0,0,0,0.5);
                }
            }
            .left-box-shadow{
                height: 100%;
                box-shadow: 0 0 70px  rgba(249,231,198,0.42) inset;
            }
            .right-box-shadow{
                height: 100%;
                box-shadow: 0 0 70px  rgba(249,231,198,0.42) inset;
            }
            .right{
                background: #ffffff;
                width: 40%;
                border-radius: 10px;
                box-shadow:0 0 8px rgba(0,0,0,0.25);
                position: relative;
                height: 170px;
                cursor: pointer;
                 &:hover{
                     box-shadow: 0px 0px 13px rgba(0,0,0,0.5);
                }
            }  
            .title-container{
                position:absolute;
                top: -22px;
                left: 10px;
            } 
            .left-item{
                width: 20%;
            }
            .right-item{
                width: 50%;
            }
            .item-div{
                display: flex;
                width: 100%;
                justify-content: center;
                padding: 10px;
                height: 100%;
                color: black;
                // .margin-item{
                //     margin: 20px 0px 20px 20px !important;
                // }
                .item{
                    font-size: 16px;
                    padding: 10px 0;
                    color: black;
                    margin: 20px;
                    border-right: 1px solid black;
                    &:nth-last-child(1){
                        border-right: none;
                    }
                        .top-div{
                            display: flex;
                            justify-content: center;
                            .div-left{
                                .title{
                                    opacity: 1;
                                    font-size: 18px;
                                    line-height: 32px;
                                    margin-bottom: 20px;
                                    color: #333333;
                                }
                                .num{
                                    font-size: 40px;
                                    margin-bottom: 5px;
                                    color: #333333;
                                    .span{
                                        font-size: 16px;
                                        line-height: 28px;
                                        color: #666;
                                        opacity: 1;
                                        padding-left: 20px;
                                    }
                                }
                            }
                            .div-left-other{
                                display: flex;
                                .title{
                                    font-weight: bold;
                                    margin-top: 20px;
                                }
                                .num-div{
                                    margin-left: 20px;
                                    .num{
                                        padding: 3px;
                                        color:#00AA58 ;
                                        border-bottom: 1px solid #00AA58;
                                        border-width: 3px;
                                        &:nth-last-child(1){
                                            padding: 5px 3px 3px 3px;
                                            border-bottom: none;
                                        }
                                    }
                                }
                                .red{
                                    padding: 3px !important;
                                    color: #EE4A39 !important;
                                    border-bottom: 1px solid #EE4A39 !important;
                                    border-width: 3px !important;
                                    &:nth-last-child(1){
                                            padding: 5px 3px 3px 3px !important;
                                            border-bottom: none !important;
                                        }
                                }
                                
                                
                            }
                            .div-right{
                                font-size: 20px;
                                margin-right: 20px;
                                .num1{
                                    line-height: 32px;
                                }
                                .num2{
                                    line-height: 32px;
                                    margin-top: 20px;
                                }
                            }
                        }
                        .bottom-div{
                            height: 60px;
                        }
                    }
                    // .background1{
                    //     background-image: linear-gradient(to right,#5770FB, #C577FF)
                    // }
                    // .background2{
                    //     background-image: linear-gradient(to right,#40ACF7,#737CFF)
                    // }
                    // .background3{
                    //     background-image: linear-gradient(to right,#ED6281,#F09D5E)
                    // }
                    // .background4{
                    //     background-image: linear-gradient(to right,#3DB684,#95CF4B)
                    // }
            }
        }

        .bottom-div{
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            .bottom-item{   
                width: 20%;
                height: 200px;
                margin: 10px 0;
                .padding-item-div{
                    cursor: pointer; 
                    box-shadow: 0 0 70px  rgba(249,231,198,0.42) inset;
                    background: #ffffff;
                    margin: 0 10px;
                    padding: 20px;
                    height: 100%;
                    border-radius: 5px;
                    &:hover{
                     box-shadow: 0px 0px 13px rgba(0,0,0,0.5);
                }
                }
            }
            .bottom-item-title{
                display: inline-block;
                margin-bottom: 5px;
                color: #ffffff;
                background:#2392ae;
                padding: 2px 20px;
                font-size: 20px;
                max-width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .bottom-item-last{
                margin-top: 10px;
                display: flex;
                font-size: 18px;
                &>div{
                    margin-right: 20px;
                }
            }
            .bottom-item-person{
                font-size: 18px;
                color: #333333;
            }
        }
       
       
    }
</style>